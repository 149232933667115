import { useEffect, useState } from 'react';
import classNames from 'classnames';
import GlobirdSelector from 'components/Shared/GlobirdSelector';
import { enumerateEnumVal, generateCheckBoxRule as chkBxRule } from 'utilities/Utility';
import {
    Form,
    Checkbox,
    Modal,
    Result,
    Alert,
    Input
} from 'antd';
import { setupDirectDebit, disposableManualDD, defaultDirectDebitAmount, deleteDirectDebit } from 'services/RequestDataService';
import {
    CreditCardDirectDebit,
    // BankAccountDirectDebit,
    EuropaDirectDebitType
} from 'data/CustomerPortalTypes';
import { selectCurrentAccount } from 'store/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import CreditCardFields, { CreditCardField } from 'components/Shared/CreditCardFields';
import SectionMenu from '../SectionMenu';
import GlobirdDivider from 'components/Shared/GlobirdDivider';
import { fetchDirectDebitInfo, getCurrentAccountDirectDebitInfo } from 'store/directDebitInfoSlice';
import ActionResultPanel from 'components/ActionResultPanel';
import links from 'data/Links';
import { useNavigate } from 'react-router-dom'; 

enum DirectDebitType {
    CreditCard = "CreditCard",
    // BankAccount = "BankAccount"
};

const directDebitDic: Record<DirectDebitType, string> = {
    // [DirectDebitType.BankAccount]: 'Bank Account',
    [DirectDebitType.CreditCard]: 'Credit Card'
};

const cardDetailErrFormFields = [
    {
        name: 'CardHolderName',
        errors: ['']
    },
    {
        name: 'CardExp',
        errors: ['']
    },
    {
        name: 'CardNumber',
        errors: ['Please check your card details.']
    }
];

const cardDetailValidFormFields = [
    {
        name: 'CardHolderName',
        errors: []
    },
    {
        name: 'CardExp',
        errors: []
    },
    {
        name: 'CardNumber',
        errors: []
    }
];

// const validateBankAccountHolderName = (name: string) => {
//     if (!name) {
//         return 'Account holder name is required.';
//     }

//     return null;
// }

// const bsbRegex = /\d{6}/;

// const validateBankAccountBsb = (bsb: string) => {
//     if (!bsb) {
//         return 'BSB is required.';
//     }

//     if (!bsbRegex.test(bsb)) {
//         return 'Invalid BSB';
//     }

//     return null;
// }

// const bankAccountNumberRegex = /^\d{6,9}$/; // 6 digits for customers with westpac bank account

// const validateBankAccountNumber = (accountNumber: string) => {
//     if (!accountNumber) {
//         return 'Account number is required.';
//     }

//     if (!bankAccountNumberRegex.test(accountNumber)) {
//         return 'Account number needs to be numbers with length of 6 to 9 digits.';
//     }

//     return null;
// }

const tAndCmsg = 'Please read and agree the direct debit service term and condition before continue.';

const ccFormInitialValue: CreditCardForm = {
    CardType: null,
    CardNumber: '',
    CardHolderName: '',
    CardExp: null,
    CardCvn: null,
    AgreeTandC: false
};

// const baFormInitialValue: BankAccountForm = {
//     AccountNumber: '',
//     AccountHolderName: '',
//     AccountBsb: '',
//     AgreeTandC: false
// };

interface DirectDebitForm {
    AgreeTandC: boolean
};

interface CreditCardForm extends DirectDebitForm, CreditCardField { }

// interface BankAccountForm extends DirectDebitForm {
//     AccountNumber: string,
//     AccountHolderName: string,
//     AccountBsb: string
// };

let submitted = false;

const DirectDebit = () => {
    const [ddType, setDdType] = useState(DirectDebitType.CreditCard);
    const [ddResult, setDdResult] = useState<boolean | null>(null);
    const [preventCcSubmit, setPreventCcSubmit] = useState(false);
    const dispatch = useDispatch();
    // const [preventBaSubmit, setPreventBaSubmit] = useState(false);

    const [ccForm] = Form.useForm<CreditCardForm>();
    // const [baForm] = Form.useForm<BankAccountForm>();

    const currentAccount = useSelector(selectCurrentAccount);
    const directDebitInfo = useSelector(getCurrentAccountDirectDebitInfo());
    const [ddAmount, setDdAmount] = useState<number | null>(null);
    const [payDD, setPayDD] = useState<boolean | null>(null);
    const [showRemoveDirectDebitModal, setShowRemoveDirectDebitModal] = useState<boolean>(false);
    const [payResult, setPayResult] = useState<boolean | null>(null);
    const [deletePreformed, setDeleteResult] = useState<boolean | null>(false);
    const navigate = useNavigate();

    useEffect(() => {

        if (!currentAccount) {
            return;
        }

        const func = async (accountId: number) => {

            const result = await defaultDirectDebitAmount(accountId);

            if (!result?.success) {
                return;
            }

            setDdAmount(result.data);
        }

        func(currentAccount.accountId);

        dispatch(fetchDirectDebitInfo(currentAccount.accountId));
        setDdType(DirectDebitType.CreditCard);
        ccForm.resetFields();
        // baForm.setFieldsValue(baFormInitialValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccount, dispatch]); // changes of directDebitInfo does not affect this effect

    if (!currentAccount) {
        return <></>;
    }

    const onCcSubmit: (event?: React.MouseEvent<Element, MouseEvent>) => void = async (e) => {
        e?.preventDefault();

        if (submitted || preventCcSubmit) {
            return;
        }

        submitted = true;

        try {
            const formResult = await ccForm.validateFields();

            const ccDto: CreditCardDirectDebit = {
                CardNumber: formResult.CardNumber.replaceAll(' ', ''),
                CardHolderName: formResult.CardHolderName,
                CardExp: formResult.CardExp!
            };

            const ccResult = await setupDirectDebit(
                currentAccount.accountId,
                ccDto);

            if (ccResult?.data) {
                setDdResult(true);
                dispatch(fetchDirectDebitInfo(currentAccount.accountId));
                ccForm.setFieldsValue(ccFormInitialValue);
                if(payDD && ddAmount != null)
                {
                    const payResult = await disposableManualDD(
                        currentAccount.accountId,
                        ddAmount);                    
                    setPayResult(payResult?.data ?? false);
                }
            }
            else if (ccResult?.data === false) {

                setPreventCcSubmit(true);

                ccForm.setFields(cardDetailErrFormFields);
            }
            else {
                setDdResult(false);
            }
        }
        finally {
            submitted = false;
        }
    };

    const deleteDirectDebitInfo: (event?: React.MouseEvent<Element, MouseEvent>) => void = async(e) => {
        e?.preventDefault();
        
        if (submitted) {
            return;
        }
        if (!currentAccount.accountId) {
            return;
        }
        submitted = true;
        setShowRemoveDirectDebitModal(false);

        try {
            const result = await deleteDirectDebit(currentAccount.accountId);
            if (result?.success === true) {
                setDeleteResult(true);
                setDdResult(true);
                dispatch(fetchDirectDebitInfo(currentAccount.accountId));
            }
        } finally {
            submitted = false;
        }
    };

    const setModalState = () => {
        setDdResult(null);
        setPayResult(null);
        ccForm.resetFields();
        setDeleteResult(null);
        navigate('/dashboard');
    }

    // const onBaSubmit: (event?: React.MouseEvent<Element, MouseEvent>) => void = async (e) => {
    //     e?.preventDefault();

    //     if (submitted || preventBaSubmit) {
    //         return;
    //     }

    //     submitted = true;

    //     try {
    //         const formResult = await baForm.validateFields();

    //         const baDto: BankAccountDirectDebit = {
    //             AccountNumber: formResult.AccountNumber,
    //             AccountHolderName: formResult.AccountHolderName,
    //             AccountBsb: formResult.AccountBsb
    //         };

    //         const baResult = await setupDirectDebit(
    //             currentAccount.accountId,
    //             undefined,
    //             baDto);

    //         if (baResult?.data) {
    //             // setDdResult(true);
    //             await func(currentAccount);
    //             baForm.setFieldsValue(baFormInitialValue);
    //         }
    //         else if (baResult?.data === false) {

    //             setPreventCcSubmit(true);

    //             baForm.setFields(
    //                 [
    //                     {
    //                         name: 'AccountNumber',
    //                         errors: ['Please check your bank account details.']
    //                     },
    //                     {
    //                         name: 'AccountHolderName',
    //                         errors: ['']
    //                     },
    //                     {
    //                         name: 'AccountBsb',
    //                         errors: ['']
    //                     }
    //                 ]
    //             );
    //         }
    //         else {
    //             setDdResult(false);
    //         }

    //         setDdResult(true);
    //     }
    //     finally {
    //         submitted = false;
    //     }
    // };

    if (directDebitInfo === undefined) { // ddInfo not loaded yet
        return <div className="form-container" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}></div>;
    }

    return (<div className="form-container">
        <div className="globird-menu-option">
            <p className="globird-menu-label">Show me: </p>
            <SectionMenu
                id="directDebitMenuSelector"
                defaultIndex={1} />
        </div>
        {ddResult !== false && <div className="field-container">
            <div className="globird-form-field">
                {directDebitInfo && <div
                    className="globird-badge badge-globird"
                    style={{
                        padding: '0.5rem 0.7rem',
                        lineHeight: '1rem',
                        fontSize: '0.8rem',
                        whiteSpace: 'normal',
                        fontWeight: 'normal'
                    }}>
                    {(directDebitInfo.paymentMethodTypeId === EuropaDirectDebitType.Card) && <p>Direct Debit is currently activated for your account with <span style={{ fontWeight: 'bold' }}>credit card</span>{directDebitInfo.creditCardNumberLastDigits && <>&nbsp;(card number ending with <span style={{ fontWeight: 'bold' }}>{directDebitInfo.creditCardNumberLastDigits}</span>)</>}.</p>}
                    {(directDebitInfo.paymentMethodTypeId === EuropaDirectDebitType.Bank) && <p>Direct Debit is currently activated for your account with <span style={{ fontWeight: 'bold' }}>bank account</span> (BSB ending with <span style={{ fontWeight: 'bold' }}>{directDebitInfo.bsbLastDigits}</span>, bank account number ending with <span style={{ fontWeight: 'bold' }}>{directDebitInfo.bankAccountLastDigits}</span>).</p>}
                </div>}
            </div>
            {/* story 23171 hidden this area, if we show this area, should delete below divider */}
            {directDebitInfo && <GlobirdDivider /> }
            {/* <div className="globird-form-field">
                <label htmlFor="title">Direct Debit Type</label><br />
                <GlobirdSelector
                    options={enumerateEnumVal<DirectDebitType>(DirectDebitType)
                        .map(ddt => ({
                            name: directDebitDic[ddt],
                            value: ddt
                        }))}
                    style={{
                        fontSize: '16px',
                        fontWeight: 'normal',
                        width: '180px',
                        borderRadius: '15px',
                        margin: '0'
                    }}
                    id="direct-debit-type-selector"
                    onSelected={op => setDdType(op.value)}
                />
            </div>
            <GlobirdDivider /> */}
            {ddType === DirectDebitType.CreditCard &&
                <Form
                    // validateTrigger="onBlur"
                    onKeyDown={e => {
                        if (e.code === 'Enter') {
                            e.preventDefault();
                            onCcSubmit();
                        }
                    }}
                    form={ccForm}
                    layout="vertical"
                    onValuesChange={() => {

                        if (!preventCcSubmit) {
                            return;
                        }

                        // enable submit and cancel errors
                        setPreventCcSubmit(false);
                        ccForm.setFields(cardDetailValidFormFields);
                        ccForm.validateFields();
                    }}
                    name="ccForm"
                    // onFinish={() => { console.log('form finished') }}
                    initialValues={ccFormInitialValue}>
                    <CreditCardFields ccForm={ccForm} cvnRequired={false} />
                    <Form.Item
                        name="AgreeTandC"
                        valuePropName="checked"
                        className="form-field-tac"
                        rules={[chkBxRule(tAndCmsg)]}>
                        <Checkbox>
                            <span style={{ fontSize: '11px' }}>
                                I have read and agreed with the
                                <a className="globird-link" target="_blank" href={links.terms.directDebitTerms} rel="noreferrer">
                                    &nbsp;Direct Debit service terms and conditions
                                </a>.
                            </span>
                        </Checkbox>
                    </Form.Item>

                    {(ddAmount != null && ddAmount > 0) && <>
                        <Form.Item
                        valuePropName="checked">
                            <Checkbox onChange={e => {setPayDD(e.target.checked)}}>
                                <span style={{ fontSize: '11px' }}>
                                    Use my direct debit details to pay my outstanding balance.
                                </span>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                        style={payDD ? {} : {display: 'none'}}>
                            <Input
                            readOnly
                            value={ddAmount || undefined}/>
                        </Form.Item>

                        {payDD && <Alert
                            message= "Warning"
                            description="If you have made a recent payment, please do not check the above option to avoid being charged twice."
                            type="warning" 
                            showIcon/>}
                    </>}
                    <div className="globird-row flex-around-center">
                        {directDebitInfo &&
                            <Form.Item>
                                <button
                                    className="globird-button-primary"
                                    style={{ width: '150px'}}
                                    onClick={(e) => {e?.preventDefault(); setShowRemoveDirectDebitModal(true)}}
                                    id="cancelDirectDebit"
                                >
                                    Delete
                                </button>
                            </Form.Item>}
                        <Form.Item>
                            <button
                                className="globird-button-primary"
                                disabled={preventCcSubmit}
                                style={{ width: '150px' }}
                                onClick={onCcSubmit}
                                id="saveUpdateDirectDebit">
                                {directDebitInfo && <>Update</>}
                                {!directDebitInfo && <>Set up</>}
                            </button>
                        </Form.Item>
                    </div>
                </Form>}
            {/* {ddType === DirectDebitType.BankAccount && // bank account DD disable untill the feature is added to our Bpoint facility
                <Form
                    // validateTrigger="onBlur"
                    form={baForm}
                    layout="vertical"
                    onKeyDown={e => {
                        if (e.code === 'Enter') {
                            e.preventDefault();
                            onBaSubmit();
                        }
                    }}
                    name="baForm"
                    initialValues={baFormInitialValue}>
                    <Form.Item
                        label="Account Holder Name"
                        // wrapperCol={{ span: 5 }}
                        name="AccountHolderName"
                        rules={[getRuleObject(validateBankAccountHolderName)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Account BSB"
                        // wrapperCol={{ span: 5 }}
                        name="AccountBsb"
                        rules={[getRuleObject(validateBankAccountBsb)]}>
                        <Input maxLength={6} />
                    </Form.Item>
                    <Form.Item
                        label="Account Number"
                        // wrapperCol={{ span: 5 }}
                        name="AccountNumber"
                        rules={[getRuleObject(validateBankAccountNumber)]}>
                        <Input maxLength={9} />
                    </Form.Item>
                    <Form.Item
                        name="AgreeTandC"
                        valuePropName="checked"
                        className="form-field-tac"
                        rules={[chkBxRule(tAndCmsg)]}>
                        <Checkbox>
                            <span style={{ fontSize: '11px' }}>I have read and agreed with the <a className="globird-link" href="/files/globird_direct_debit_service_term_and_conditions.pdf">Direct Debit service terms and conditions.</a>
                            </span>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <button
                            className="globird-button-primary"
                            style={{ width: '200px' }}
                            onClick={onBaSubmit}>
                            Submit
                        </button>
                    </Form.Item>
                </Form>} */}
        </div>}
        {ddResult === false && <div className="field-container">
            <ActionResultPanel status="error">
                Sorry, we are unable to setup direct debit service for your account at this moment, please try again later or contact our customer service for help.
            </ActionResultPanel>
        </div>}
        <Modal
            visible={ddResult === true}
            onOk={setModalState}
            cancelButtonProps={{ style: { display: 'none' } }}
            closable
            okButtonProps={{
                size: 'large',
                style: { minWidth: '150px', margin: '0 auto', display: 'block' },
                id: 'okButton'
            }}
            centered>
                {
                    !deletePreformed && <Result
                    status="success"
                    title="Your direct debit details have been successfully saved." />
                }
                {
                    deletePreformed && <Result
                    status="success"
                    title="Your direct debit details have been successfully deleted." />
                }
                {
                    payResult === true && !deletePreformed && <Result
                    status="success"
                    title={<>Thank you for your payment! <br /> It will take a few minutes to process, along with any applicable pay-on-time discount, before it reflects on your balance.</>}
                    />
                }
                {
                (payResult === null && payDD && !deletePreformed) && <Result
                    title="Attempting to process your payment, this will take a moment."/>
                }
                {
                (payResult === false && payDD && !deletePreformed) && <ActionResultPanel status="error">
                    Sorry, we are unable to process your payment at this moment, please try again later or contact our customer service for help.
                </ActionResultPanel>
                }
             {/* Title needs to be variable depending on making a payment aswell or not */}
        </Modal>
        <Modal
            visible={showRemoveDirectDebitModal}
            onCancel={() => setShowRemoveDirectDebitModal(false)}
            onOk={deleteDirectDebitInfo}
            closable={false}
            centered>
            Cancelling direct debit, please note:
            <ul style={{ marginBottom: '0' }} >
                <li>You'll need to pay bills manually using one of the payment methods listed on your bill. </li>
                <li>If your current energy plan has a conditional direct debit discount, you will no longer receive it. </li>
            </ul>
        </Modal>
    </div>);
}

export default DirectDebit;